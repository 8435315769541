import React from 'react'
import { graphql } from 'gatsby'

import BaseLayout from './base'
import Head from '../components/head'
import SocialCard from '../components/social-card'
import PageHeader from '../components/page-header'
import PostMetadata from '../components/post-metadata'
import RelatedPosts from '../components/related-posts'

const BlogPostTemplate = props => {
	const { previous, previousPath, next, nextPath } = props.pageContext
	const post = props.data.markdownRemark
	const frontmatter = post.frontmatter
	const image = (frontmatter.image && frontmatter.image.childImageSharp.original.src) ||
		(frontmatter.largeImage && frontmatter.largeImage.childImageSharp.original.src)
	const isLargeImage = image && frontmatter.largeImage
	
	const postContainsFootnotes = post.html.includes(`<div class="footnotes">`)
	const postContainsCodeBlock = post.html.includes(`<pre`)
	const postClassName = "post"
		+ (postContainsFootnotes ? " contains-footnotes" : "")
		+ (postContainsCodeBlock ? " contains-code-block" : "")
	
	return(
		<BaseLayout mainWrapperClassName="blog-post-main-wrapper">
			<article className={postClassName} itemScope itemType="http://schema.org/BlogPosting">
				<Head title={frontmatter.title} />
				<SocialCard
					title={frontmatter.title}
					description={frontmatter.description}
					image={image}
					isLargeImage={isLargeImage}
				/>
				<PageHeader 
					title={frontmatter.title}
					description={frontmatter.description}
				/>
				<div
					className="entry-content"
					itemProp="articleBody"
					dangerouslySetInnerHTML={{ __html: post.html }}
				/>
			</article>
			<PostMetadata
				date={frontmatter.date}
				link={props.location.pathname}
				tags={frontmatter.tags}
			/>
			{ (previous || next) && 
				<RelatedPosts 
					previous={previous} previousPath={previousPath} 
					next={next}         nextPath={nextPath}
				/>
			}
		</BaseLayout>
	)
}

export default BlogPostTemplate

export const postQuery = graphql`
	query($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				description
				image {
					childImageSharp {
						original {
							src
						}
					}
				}
				largeImage {
					childImageSharp {
						original {
							src
						}
					}
				}
				slug
				date
				tags
			}
		}
	}
`;
