import React from 'react'
import { Link } from 'gatsby'

import ArrowIcon from '../assets/arrow.svg'

const RelatedPost = ({className, post, postLink}) => {
  const postClass = className + (postLink == null ? "" : " has-link")
  const title = post == null ? null : post.frontmatter.title
  const description = post == null ? null : post.frontmatter.description
  
  return(
    <div className={postClass}>
      {
        postLink &&
        <Link to={postLink} className="related-post-link">
          <ArrowIcon className="arrow" />
          <h1 className="title">{title}</h1>
          <h2 className="description">{description}</h2>
        </Link>
      }
    </div>
  )
}

const RelatedPosts = ({previous, previousPath, next, nextPath}) => (
  <div className="related-posts-container">
    <RelatedPost
      className={"related-post previous-post"}
      post={previous} 
      postLink={previousPath}
     />
    <RelatedPost 
      className={"related-post next-post"}
      post={next} 
      postLink={nextPath}
    />
  </div>
)

export default RelatedPosts
